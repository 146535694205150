<template>
    <OModal ref="connectExistingObjectsToActsDialog" >
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="hstack gap-2">
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Select Activity to add selected objects") }}
                        </h4>
                        <div v-if="isExecutingProc" class="spinner-border spinner-border-sm" style="0.35rem;" role="status">
                            <span class="visually-hidden">{{ $t('Processing...') }}</span>
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row">
                    <p>{{$t("Selected objects will be added as target objects for the selected activity.")}}</p> 
                    <div class="mb-2 mt-2" style="font-weight:600;font-size:smaller" >
                        {{ selectedObjectsCount }} {{$t(" objects selected")}}
                    </div>
                    <div style="max-width:600px">
                        {{$t("Activity")}}:
                        <ActivitiesLookup 
                                textInput
                                :bind="sel=>{ActivityID = sel.ID; Activity = sel.TypeAndName;}"
                                class="form-control form-control-sm "
                                :textInputValue="Activity"
                                 />
                    </div> 
                </div>
                            
                <div class="modal-footer">
                    <div style="display:inline;font-size:small;" class="me-2" v-if="selectedObjectsCount===0">
                        <div class="text-danger">{{ $t("No objects selected") }}</div>
                    </div>
                    <button class="btn btn-outline-primary" :disabled="selectedObjectsCount===0 || isExecutingProc" @click="assignToActivity">{{$t("Assign to Activity")}}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
import { ref, defineExpose, computed, watch } from 'vue';
import OModal from 'o365.vue.components.Modal.vue'; 
import alert from 'o365.controls.alert.ts';
import ActivitiesLookup from "complete.vue.components.ActivitiesLookup.vue";

const ActivityID = ref();
const Activity = ref();

const procSubmit = getOrCreateProcedure({ id: "procSubmit" + crypto.randomUUID(), procedureName: "astp_Complete_AssignObjectsToActivity" });
const connectExistingObjectsToActsDialog = ref();
const isExecutingProc = ref(false);

function assignToActivity() {
    if (props.objectsDataObject.selectionControl.selectedRows.length>0) {
        const ids = props.objectsDataObject.selectionControl.selectedRows;
        const preparedRows = [];
        ids.forEach(sel => {
            if (sel.ID !== null) preparedRows.push([sel.ID]);
        });
        doWork(preparedRows);
    }
}

defineExpose({ show });

const props = defineProps({
    objectsDataObject: {
        type: Object,
        required: true
    }
});

const selectedObjectsCount = computed(() => props.objectsDataObject.selectionControl.selectedRows.length);

async function show() { 
    Activity.value = "";
    ActivityID.value = null;
    connectExistingObjectsToActsDialog.value?.show();
}

async function doWork(ids) {
    isExecutingProc.value = true;
    await procSubmit.execute({
        Objects: ids,
        Activity_ID : ActivityID.value
    }).then(
        (response, err) => {
            var vMessage = $t("Objects assigned to Activity");                
            alert(vMessage, 'success', {autohide: true, delay: 3000});                
            connectExistingObjectsToActsDialog.value.hide();
            props.objectsDataObject.load();        
        }
    ).finally(() => {
        isExecutingProc.value = false;
    });    
}

</script>